"use strict";
/*!
 *
 *  nav-current.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem, option) {
        this.setting = {
            currentClass: 'is-current',
            abs: false,
            not: undefined,
            root: '/'
        };
        this.URLPath = location.pathname + location.search;
        this.URLLocate = location.protocol + '//' + location.hostname;
        $.extend(this.setting, option);
        this.$elem = $(elem);
        this.setCurrent();
    }
    default_1.prototype.setCurrent = function () {
        var _this = this;
        this.$elem.find('a').each(function (i, elem) {
            if (_this.setting.not !== undefined && $(elem).is(_this.setting.not)) {
                return;
            }
            ;
            var href = $(elem).attr('href');
            href = href !== undefined ? href.replace(_this.URLLocate, '') : undefined;
            if (href !== undefined) {
                var isCurrent = (_this.URLPath.search(href) == 0);
                if (_this.setting.abs || href === _this.setting.root) {
                    isCurrent = (_this.URLPath === href);
                }
                ;
                if (isCurrent) {
                    $(elem).addClass(_this.setting.currentClass);
                    if ($(elem).closest('.st-Local_List_Item').length) {
                        $(elem).closest('.st-Local_List_Item').children('.st-Local_List_Item_Link').addClass(_this.setting.currentClass);
                    }
                    ;
                    if ($(elem).closest('.st-Local_List2_Item').length) {
                        $(elem).closest('.st-Local_List2_Item').children('.st-Local_List2_Item_Link').addClass(_this.setting.currentClass);
                    }
                    ;
                }
                else {
                    $(elem).removeClass(_this.setting.currentClass);
                }
                if (_this.setting.abs) {
                    var _URL = _this.URLPath.replace(/(.+\/)(.+\.html)$/g, '$1');
                    if (href === _URL) {
                        $(elem).addClass(_this.setting.currentClass);
                        if ($(elem).closest('.st-Local_List_Item').length) {
                            $(elem).closest('.st-Local_List_Item').children('.st-Local_List_Item_Link').addClass(_this.setting.currentClass);
                        }
                        ;
                        if ($(elem).closest('.st-Local_List2_Item').length) {
                            $(elem).closest('.st-Local_List2_Item').children('.st-Local_List2_Item_Link').addClass(_this.setting.currentClass);
                        }
                        ;
                    }
                    else {
                        var _url = _URL.split('/');
                        if (_url.length > 6) {
                            _url.pop();
                            _url.pop();
                            var __url = _url.join('/') + '/';
                            if (href === __url) {
                                $(elem).addClass(_this.setting.currentClass);
                                if ($(elem).closest('.st-Local_List_Item').length) {
                                    $(elem).closest('.st-Local_List_Item').children('.st-Local_List_Item_Link').addClass(_this.setting.currentClass);
                                }
                                ;
                                if ($(elem).closest('.st-Local_List2_Item').length) {
                                    $(elem).closest('.st-Local_List2_Item').children('.st-Local_List2_Item_Link').addClass(_this.setting.currentClass);
                                }
                                ;
                            }
                            ;
                        }
                        ;
                    }
                }
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
