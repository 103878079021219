/*!
 *
 *  load-image.js
 *
 */

module.exports = function( src ){
	const d = new $.Deferred;
	const $img = $('<img />');
	$img.attr('src', src).on('load', function(){
		d.resolve();
	});
	return d.promise();
};

