"use strict";
/*!
 *
 *  switch-fontsize.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
require("jquery.cookie");
var default_1 = /** @class */ (function () {
    function default_1(options) {
        var _this = this;
        this.defo = {
            cookieName: 'font-size',
            btn: '.font-switch-btn',
            target: 'html'
        };
        options ? options : {};
        this.setting = $.extend({}, this.defo, options);
        this.setting.class = this.setting.btn.replace(/\./, '');
        var current = this.checkCookie();
        $(this.setting.btn).each(function (index, element) {
            if ($(element).val() === current) {
                _this.setSize(element, current);
            }
            ;
        });
        $(this.setting.btn).on('click', function (e) {
            e.preventDefault();
            var element = e.currentTarget;
            if (!$(element).is(_this.setting.btn + '-current')) {
                _this.setSize(element, $(element).val());
            }
            ;
        });
        $(this.setting.btn).on('keydown', function (e) {
            var element = e.currentTarget;
            if (e.keyCode === 13) {
                e.preventDefault();
                if (!$(element).is(_this.setting.btn + '-current')) {
                    _this.setSize(element, $(element).val());
                }
                ;
            }
            ;
        });
    }
    default_1.prototype.checkCookie = function () {
        var cookie = $.cookie(this.setting.cookieName);
        if (cookie) {
            return cookie;
        }
        else {
            $.cookie(this.setting.cookieName, 'default', { expires: 7, path: '/' });
            return 'default';
        }
        ;
    };
    default_1.prototype.setSize = function (btn, size) {
        if (size === 'large') {
            $(this.setting.target).addClass('sw-FontLarge');
        }
        else {
            $(this.setting.target).removeClass('sw-FontLarge');
        }
        ;
        $(this.setting.btn + '-current').removeClass(this.setting.class + '-current');
        $(btn).addClass(this.setting.class + '-current');
        $.removeCookie(this.setting.cookieName);
        $.cookie(this.setting.cookieName, size, { expires: 7, path: '/' });
    };
    return default_1;
}());
exports.default = default_1;
